/* //@import url('https://fonts.googleapis.com/css?family=Sarabun&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');
body {
  margin: 0;
  font-family: 'Prompt', sans-serif!important;
  -webkit-font-smoothing: antialiased!important;
  -moz-osx-font-smoothing: grayscale!important;
}

#apppage .gradient {
  /* background: -moz-linear-gradient(
    45deg,
    rgba(244, 66, 96, 0.7),
    rgba(161, 13, 25, 0.7) 100%
  ); */
  /* background: -webkit-linear-gradient(
    45deg,
    rgba(244, 66, 96, 0.7),
    rgba(161, 13, 25, 0.7) 100%
  ); */
  background: -webkit-gradient(
    linear,
    45deg,
    from(rgba(66, 133, 244, 0.7)),
    to(rgba(13, 71, 161, 0.7))
  );
  background: -o-linear-gradient(
    45deg,
    rgba(244, 66, 96, 0.7),
    rgba(161, 13, 25, 0.7) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(243, 242, 242, 0.925),
    rgba(226, 217, 218, 0.795) 10%
  );
}

#apppage .view {
  /* background-image: url("https://mdbootstrap.com/img/Photos/Others/architecture.webp"); */
  /* background-image: url("https://i.ytimg.com/vi/VFTeAXOOcEg/maxresdefault.jpg"); */
  background-image: url("https://chiangmaibankforklift.com/bankbackend/S__53501976.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 35vh;
  margin-top: 0 ;
  
}

#apppage h6 {
  line-height: 1.7;
}

body, html {
  height: 100%;
}
.bg {
  /* background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(11).webp"); */
  background-image: url("https://images.pexels.com/photos/3377405/pexels-photo-3377405.jpeg?cs=srgb&dl=pexels-el%C4%ABna-ar%C4%81ja-3377405.jpg&fm=jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}
.App {
  font-family: sans-serif;
  text-align: center;
}

.toplea {
  position: absolute; 
  top: 500px;  
}

.ww {
  /* background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(11).webp"); */
  height: 60% !important;
  width: 80% !important;
  position: absolute !important;
  padding-left: 2px !important;
  /* padding-Right: 2px !important; */
  margin: left 5px ;
  /* margin: 0 auto; */
  /* left: 3;
  right: 5; */
  /* top: 0;
  bottom: 0;
  margin: auto; */

}
.element.style{
  width: 80% !important;
}

.h1 {
  font-size: 40px;
  background: -webkit-linear-gradient(#b80303, #1f1e1e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* -webkit-text-stroke: 0.3px rgb(245, 242, 242); */
}

.aa {
  font-size: 30px;
  background: -webkit-linear-gradient(#b80303, #1f1e1e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* -webkit-text-stroke: 0.3px rgb(245, 242, 242); */
}




